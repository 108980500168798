import React from 'react';
export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper jzih1dpqqrg-editor_css' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo jzjgnya1gmn-editor_css',
    children: 'https://firebasestorage.googleapis.com/v0/b/binale-landing.appspot.com/o/binale_logo_white.png?alt=media&token=31cdf00f-67d5-45a4-a91e-8cc159fe65fe',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item2',
        className: 'header3-item',
        children: {
          href: '#',
          children: [],
        },
        subItem: null,
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner20DataSource = {
  wrapper: { className: 'banner2' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        page: { className: 'home-page banner2-page' },
        textWrapper: { className: 'banner2-text-wrapper' },
        bg: { className: 'bg bg0 lm5323ax57b-editor_css' },
        title: {
          className: 'banner2-title',
          children: (
            <span>
              <span>
                <span>
                  <p></p>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'banner2-content lmkld53upyd-editor_css',
          children: (
            <span>
              <span>
                <p>Ihre Cloud-Buchhaltung jederzeit und überall</p>
              </span>
            </span>
          ),
        },
      },
    ],
  },
};
export const Feature00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0 lm539l0jhom-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Cloud-basierte...</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper lm539pncyxe-editor_css',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://cdn1.iconfinder.com/data/icons/competitive-strategy-and-corporate-training/512/695_Accounting_audit_banking_calculation_calculator_bank_banking_money_business-512.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>Buchhaltung</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://cdn4.iconfinder.com/data/icons/linecon/512/folder-512.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>DMS</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>Dokumenten-Management System</p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://cdn4.iconfinder.com/data/icons/fluent-solid-20px-vol-1/20/ic_fluent_book_contacts_20_filled-512.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>Kontakte</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~lm539rs85jc',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon lm53yzbti7-editor_css',
              children:
                'https://cdn1.iconfinder.com/data/icons/bootstrap-vol-2/16/file-earmark-pdf-512.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>PDF-Tool</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Feature01DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Buchhaltung</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block lmklivphwu-editor_css',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon lm53jkfldd-editor_css',
              children:
                'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>Erfassung</p>
                    <p>
                      <br />
                    </p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <p>
                                  Erfassen Sie alle relevanten Sachverhalte
                                  schnell und bequem
                                </p>
                                <p>
                                  <br />
                                </p>
                                <p>- Ausgangsrechnungen</p>
                                <p>- Eingangsrechnungen</p>
                                <p>- Banken</p>
                                <p>- Kassen</p>
                                <p>- Entgeltabrechnungen</p>
                                <p>- Kassenabrechnungen (POS)</p>
                                <p>- Sonstige Buchungen (Freie Erfassung)</p>
                                <p>
                                  <br />
                                </p>
                                <p>
                                  Einzelne Module orientieren sich an den Buchhaltungsbereichen und enthalten spezifische Erfassungshilfen<br />
                                </p>
                                <p>
                                  <br />
                                </p>
                                <p>
                                  Erfassung von Kostenstellen<br />
                                </p>
                                <p>
                                  <br />
                                </p>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              className: 'lm53krg0mva-editor_css',
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block lmklhqms6j-editor_css',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon lm53jxaiyt-editor_css',
              children:
                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title lmklijlz8sq-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>Verwendung</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>Standart-Kontenrahmen</p>
                          <p>SKR03 &amp; SKR04</p>
                          <p>
                            <br />
                          </p>
                          <p>Versteuerung der Umsätze</p>
                          <p>- Soll-Versteuerung (vereinbarte Entgelte)</p>
                          <p>- Ist-Versteuerung (vereinnahmte Entgelte)</p>
                          <p>- ohne Steuerberechnung (z.B. Kleinunternehmer)</p>
                          <p>
                            <br />
                          </p>
                          <p>
                            Farbige Markierungen und Notizen machen Ihre Arbeit
                            noch produktiver und bequemer<br />
                          </p>
                          <p>
                            <br />
                          </p>
                          <p>
                            Automatische Verbuchung von Skontobeträgen<br />
                          </p>
                          <p>
                            <br />
                          </p>
                          <p>
                            Bequeme Erfassung und Verrechnung von Anzahlungen<br />
                          </p>
                          <p>
                            <br />
                          </p>
                          <p>
                            Fügen Sie Ihre Belege in Sekunden den Buchungen
                            hinzu<br />
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              className: 'lmklhs3hbn-editor_css',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block lmkllxevxbi-editor_css',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon lm53jypvwz-editor_css',
              children:
                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>Ihre Daten sind jederzeit und überall verfügbar. </p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>Einzige Voraussetzung ist der Internetzugang</p>
                      <p>
                        <br />
                      </p>
                      <p>
                        Gleichzeitiges Arbeiten von mehreren Personen mit
                        Real-Time-Aktualisierung<br />
                      </p>
                      <p>
                        <br />
                      </p>
                      <p>
                        Schnelles Anlegen und Verwalten von Sachkonten,
                        Debitoren, Kreditoren und Kostenstellen<br />
                      </p>
                      <p>
                        <br />
                      </p>
                      <p>
                        und vieles mehr...<br />
                      </p>
                    </span>
                  </span>
                </span>
              ),
              className: 'lm53nrwvck-editor_css',
            },
          ],
        },
      },
    ],
  },
};
export const Feature70DataSource = {
  wrapper: { className: 'home-page-wrapper feature7-wrapper' },
  page: { className: 'home-page feature7' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature7-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature7-title-h1',
        children: (
          <span>
            <p>Weitere Leistungen</p>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'feature7-title-content lm53pogfu2r-editor_css',
        children: '你可以直接快速接入图像能力',
      },
    ],
  },
  blockWrapper: {
    className: 'feature7-block-wrapper lmklxawkf56-editor_css',
    gutter: 24,
    children: [
      {
        md: 6,
        xs: 24,
        name: 'block0',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image lm544oxm9zg-editor_css',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title lm544uzjlr-editor_css',
              children: (
                <span>
                  <span>
                    <p>Auswertungen</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>Buchungsübersicht</p>
                        <p>mit Filter- und Suchfunktion</p>
                        <p>
                          <br />
                        </p>
                        <p>Kontenauskunft</p>
                        <p>mit Suchfunktion</p>
                        <p>
                          <br />
                        </p>
                        <p>
                          Summen und Salden<br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          Umsatzsteuer-Voranmeldung<br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          Gewinn- und Verlustrechnung<br />
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block1',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image lm5451pbke-editor_css',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title lm545ic1mw9-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <p>Export und Import von Daten</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>
                    Schneller und bequemer Datenaustausch mit Ihrem steuerlichen
                    Berater dank DATEV-Schnittstelle
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Import von Buchhaltungsdaten aus anderen Programmen (z.B.
                    ERP-, Warenwirtschafts- oder Kassen-Systeme<br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block2',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image lm5457g7q4d-editor_css',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title lm545m3m0jb-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <p>Unser fachlicher und technischer Support</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>
                    Wir unterstützen Sie gern bei allen Angelegenheiten in
                    Zusammenhang mit Ihrer Buchhaltung
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Feature02DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>Dokumenten-Management System (DMS)</p>
            </span>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper lmklqmwx9o-editor_css',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon lm5b924yh4o-editor_css',
              children:
                'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>Sicher</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    Sichere Ablage und bequeme Verwaltung für Ihre
                    Unternehmensdokumentation
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon lm5b9dlra2-editor_css',
              children:
                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>Bequem</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>Verknüpfen Sie die Belege mit der Buchhaltung</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Feature03DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>Kontakte</p>
            </span>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper lmklt8o709-editor_css',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon lm5ba4nny1-editor_css',
              children:
                'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>Alle Kontakte an einem Ort</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    Verwalten Sie Kontaktdaten zu allen Ihren Geschäftspartnern
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon lm5ba7c93hm-editor_css',
              children:
                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <p>Voll Integriert</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          Bequeme Verknüpfung zur Buchhaltung und DMS<br />
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          Ordnen Sie Ihre Kontakte zu Debitoren und Kreditoren
                          in der Buchhaltung
                        </p>
                        <p>
                          <br />
                        </p>
                        <p>
                          Noch schnellere Erfassung und Ablage der Unterlagen in
                          DMS dank Verknüpfung
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              className: 'lmklrvkg5mt-editor_css',
            },
          ],
        },
      },
    ],
  },
};
export const Feature04DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Impressum</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper lo38m9g9mze-editor_css',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 18,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>Angaben gemäß § 5 TMG</p>
                      <p>
                        <br />
                      </p>
                      <p>
                        <b>Binale GmbH &amp; Co. KG</b>
                      </p>
                      <p>Industriestr. 5</p>
                      <p>77955 Ettenheim</p>
                      <p>Deutschland</p>
                      <p>Telefon: +49 7822 417 33 46</p>
                      <p>E-Mail: info@binale.de</p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block lo38n1knupj-editor_css',
        md: 8,
        xs: 18,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'content',
              children: (
                <span>
                  <p>Umsatzsteuer-ID: DE 323 312 041</p>
                  <p>Registergericht: Amtsgericht Freiburg im Breisgau</p>
                  <p>Registernummer: HRA 705921</p>
                  <p>Komplementärin: LETRIX Verwaltung GmbH (Sitz in Berlin)</p>
                  <p>Geschäftsführer: Oliver Weigel</p>
                  <p>Registergericht: Amtsgericht Charlottenburg</p>
                  <p>Registernummer: HRB 145143 B</p>
                  <p>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV ist</p>
                  <p>Oliver Weigel</p>
                  <p>Binale GmbH &amp; Co. KG</p>
                  <p>Industriestr. 5</p>
                  <p>77955 Ettenheim</p>
                  <p>Deutschland</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper lo38fdmwqp-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <p>kontaktieren Sie uns</p>
              </span>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>
                    <span>Telefon: +49 7822 417 33 46</span>
                    <br />
                  </p>
                  <p>E-Mail: info@binale.de</p>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'title-content lo38hxma2ma-editor_css',
      },
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page lm52ewbbr4f-editor_css',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 8,
        className: 'block',
        title: {
          className: 'logo jzl0qcpyjra-editor_css',
          children: 'https://firebasestorage.googleapis.com/v0/b/binale-landing.appspot.com/o/binale_logo_notext.svg?alt=media&token=aff4e6dd-cc3c-4ee5-8b7d-774ec816675b',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <span>
                    <p>Binale GmbH &amp; Co KG</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~lo38veuc4vm',
        xs: 24,
        md: 8,
        className: 'block',
        title: {
          children: (
            <span>
              <span>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              href: 'https://binale.app',
              name: 'link0',
              target: "_blank",
              children: (
                <span>
                  <b>Binale App</b>
                </span>
              ),
            },
            {
              href: '#',
              name: 'link0',
              children: (
                <span>
                  <p>AGB</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <span>
          <span>
            <span>
              &nbsp; &nbsp; &nbsp; Copyright ©
              2023 Binale GmbH &amp; Co KG<br />
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
